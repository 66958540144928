.top-bar {
  width: 100%;
  display: none;
  z-index: 1000;
  min-height: 56px !important;
  max-height: 56px !important;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  overflow: hidden;

  &[data-fixed="1"] {
    z-index: 1002;
  }

  @media (min-width: 1025px) {
    &[data-desktop="1"] {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    &[data-mobile="1"] {
      display: block;
    }
  }


  .top-bar-wrap {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
    max-width: 1200px !important;
    margin: auto !important;
    position: relative;
    align-items: center;
    height: 56px !important;
  }

  .top-bar-left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-left: 15px;

    .widget-area {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;

      .widget {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .simple-social-icons ul li:first-child {
      margin-left: 0 !important;
    }
  }

  .top-bar-right {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    .widget-area {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;

      .widget {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .simple-social-icons ul li:last-child {
      margin-right: 0 !important;
    }
  }

  .top-bar-mobile {
    display: none;
  }

  .simple-social-icons ul {
    margin: 0 !important;

    li {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }

  @media (max-width: 1024px) {
    .top-bar-left, .top-bar-right {
      display: none !important;
    }
    .top-bar-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .widget-area {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}


// Offsets
.top-bar {
  &[data-fixed="1"] {
    position: fixed;
  }
}










